import Image from 'next/image'
import { FC } from 'react'
import { classNames } from '../../services'
import ComponentWrapper from '../wrapper/ComponentWrapper'

interface OfficeProps {}

const features = [
  {
    name: 'Neue Behandlungsliegen',
    description:
      'Unsere Praxis ist nicht nur auf dem neuesten Stand der Technik – sie ist für alle Mitarbeiter, Ärzte/-innen und Patienten/innen ein angenehmer und professioneller Ort: Wer optimale zahnärztliche Betreuung sucht, kommt an uns nicht vorbei. Wir freuen uns, dass Sie unsere Praxis kennenlernen möchten..',
    imageSrc: '/dental-office.jpg',
    imageAlt:
      'White canvas laptop sleeve with gray felt interior, silver zipper, and tan leather zipper pull.',
  },
  {
    name: 'Klimatisiert',
    description:
      'Weder vor Angst noch vor Hitze soll bei uns geschwitzt werden. Daher sind alle Zimmer unserer Praxis, in denen Sie sich aufhalten, vollklimatisiert.',
    imageSrc: '/dental-office-2.jpg',
    imageAlt: 'Detail of zipper pull with tan leather and silver rivet.',
  },
  {
    name: 'Angenehme Besuche',
    description:
      'Wir geben alles, damit Sie sich während Ihres Aufenhaltes bestmöglich aufgehoben fühlen. Dazu gehören Wasserspender und Unterhaltungsmöglichkeiten im Warteraum sowie frisch renovierte WCs.',
    imageSrc: '/dental-work.jpg',
    imageAlt: 'Detail of zipper pull with tan leather and silver rivet.',
  },
]

const Office: FC<OfficeProps> = ({}) => {
  return (
    <div className='w-full'>
      <div className='max-w-2xl mx-auto py-24 px-4 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8'>
        <div className='max-w-3xl mx-auto text-center'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            Moderne Räumlichkeiten & Ausstattung
          </h2>
          <p className='mt-4 text-gray-500'>
            Unsere neuen, barrierefreien Praxisräumlichkeiten ermöglichen modernste Behandlungsmethoden. Sie
            sind mit verschiedenen Farben und Formen aus Holz gestaltet. Es gibt Kletterbäume, eine Spielhöhle
            und Parkmöglichkeiten für Ihren Kinderwagen.
          </p>
        </div>

        <div className='mt-16 space-y-16'>
          {features.map((feature, featureIdx) => (
            <div
              key={feature.name}
              className='flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center'>
              <div
                className={classNames(
                  featureIdx % 2 === 0 ? 'lg:col-start-1' : 'lg:col-start-8 xl:col-start-9',
                  'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-5 xl:col-span-4'
                )}>
                <h3 className='text-lg font-medium text-gray-900'>{feature.name}</h3>
                <p className='mt-2 text-sm text-gray-500'>{feature.description}</p>
              </div>
              <div
                className={classNames(
                  featureIdx % 2 === 0 ? 'lg:col-start-6 xl:col-start-5' : 'lg:col-start-1',
                  'flex-auto lg:row-start-1 lg:col-span-7 xl:col-span-8'
                )}>
                <div className='relative rounded-lg overflow-hidden'>
                  <Image
                    quality={100}
                    layout='responsive'
                    objectFit='contain'
                    width={800}
                    height={320}
                    src={feature.imageSrc}
                    alt={feature.imageAlt}
                    className='object-center object-cover rounded-lg'
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ComponentWrapper(Office, 'office', 'bg-white')
